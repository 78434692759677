export default {
    actions: {
        fetchAllAchievements( ctx ) {
            ctx.dispatch('apiGetAllAchievements', {
                success: ( data ) => {
                    ctx.commit('updateAllAchievements', data);
                },
            });
        },
        fetchNewAchievements( ctx ) {
            ctx.dispatch('apiGetNewAchievements', {
                success: ( data ) => {
                    ctx.commit('updateNewAchievements', data);
                },
            });
        },
        fetchAchievementMap( ctx ) {
            ctx.dispatch('apiGetAchievementMap', {
                success: ( data ) => {
                    ctx.commit('updateAchievementMap', data);
                },
            });
        },
    },
    mutations: {
        updateAllAchievements( state, data ) {
            state.allAchievements = data;
        },
        updateNewAchievements( state, data ) {
            state.newAchievements = data;
        },
        updateAchievementMap( state, data ) {
            state.achievementMap = data;
        },
    },
    state: {
        allAchievements: undefined,
        newAchievements: undefined,
        achievementMap: undefined,
    },
    getters: {
        getAllAchievements( state ) {
            return state.allAchievements;
        },
        getCurrAchievement( state ) {
            if (state.allAchievements === undefined) {
                return null;
            }
            return state.allAchievements[state.allAchievements.length - 1];
        },
        getNewAchievements( state ) {
            return state.newAchievements;
        },
        getAchievementMap( state ) {
            return state.achievementMap;
        },
    },
};
