import axios from "axios";

const baseApiUrl = process.env.VUE_APP_API_BASE_URL;
const basePlatformUrl = process.env.VUE_APP_PLATFORM_BASE_URL;
const loginUrl = basePlatformUrl + process.env.VUE_APP_LOGIN_PATH;

const slowDown = process.env.NODE_ENV === 'production' ? 0 : 0;
const defaultMethod = "post";
const preloaderDefault = false;

axios.defaults.withCredentials = true;

export default {
    actions: {
        apiQuery(ctx, request) {
            // inc preloader
            if (request.preloader === undefined) {
                request.preloader = preloaderDefault;
            }
            if (request.preloader === true) {
                ctx.commit('incrementPreloaderState');
            }

            setTimeout(() => {
                axios.request({
                    url: baseApiUrl + request.url,
                    data: request.data,
                    method: request.method === undefined ? defaultMethod : request.method,
                    preloader: request.preloader === undefined ? preloaderDefault : request.preloader,
                    params: request.params
                })
                    .then(response => {
                        if (request.success !== undefined && typeof request.success === 'function') {
                            request.success(response.data.data)
                        }

                        // first update the content then disable preloader!
                        if (response.config.preloader === true) {
                            ctx.commit('decrementPreloaderState');
                        }

                    })
                    .catch(error => {
                        if (request.error !== undefined && typeof request.error === 'function') {
                            request.error(error)
                        }

                        ctx.commit('addApiError', error);

                        if (error.config.preloader === true) {
                            ctx.commit('decrementPreloaderState');
                        }
                    });
            }, slowDown);
        },

        redirectToLogin(ctx) {
            console.log("REDIRECT TO LOGIN");

            const loginUrl = process.env.VUE_APP_PLATFORM_BASE_URL + process.env.VUE_APP_LOGIN_PATH;
            window.location = loginUrl + "?gpath=" + window.location.pathname;
        }
    },

    state: {
        apiErrors: [],
        preloaderState: 0,
    },

    mutations: {
        addApiError(state, error, smth) {
            const err = {
                title: "Error",
                description: "Unknown error.",
                redirect: null,
                rawError: error
            };

            switch (error.response?.status) {
                case 0:
                    err.title = "Network error";
                    err.description = "API is unreachable";
                    break;
                case 400:
                case 500:
                    err.description = error.response.data.error.message;
                    break;

                case 403:
                    // state.globalRedirect = "/";
                    this.commit('setProfileStatus', 0);
                    break;

                case 401:
                case 402:
                case 423:
                    this.dispatch('redirectToLogin');
                    break;

                case 406:
                    err.title = "Already registered!";
                    err.description = "You have registered before. Return to the main page!"
                    err.redirect = '/';
                    break;

                default:
                    console.log(error)
            }

            state.apiErrors.push(err);
        },

        removeApiError(state) {
            if (state.apiErrors.length > 0) {
                state.apiErrors.shift();
            }
        },

        incrementPreloaderState(state, n) {
            if (n === undefined || isNaN(n)) {
                state.preloaderState++;
            } else {
                state.preloaderState += n;
            }

            if (state.preloaderState < 0) {
                console.log("preloaderState(" + state.preloaderState + "): am i a joke to you?")
                state.preloaderState = 0;
            }
        },

        decrementPreloaderState(state, n) {
            if (n === undefined || isNaN(n)) {
                state.preloaderState--;
            } else {
                state.preloaderState -= n;
            }

            if (state.preloaderState < 0) {
                console.log("preloaderState(" + state.preloaderState + "): am i a joke to you?")
                state.preloaderState = 0;
            }
        },
    },

    getters: {
        getApiError(state) {
            if (state.apiErrors.length > 0) {
                return state.apiErrors[0];
            }

            return null;
        },

        getPreloaderState(state) {
            return state.preloaderState > 0;
        },
    }
}
