export default {
    actions: {
        fetchProfileData( ctx ) {
            ctx.dispatch('apiProfileInfo', {
                success: ( data ) => {
                    ctx.commit('updateProfileData', data);
                },
            });
        },
        async fetchAvailableLoginPresent( ctx ) {
            ctx.dispatch('apiAvailableLoginPresent', {
                success: ( data ) => {
                    ctx.commit('updateAvailableLoginPresent', data);
                },
            });
        },
        async getLoginPresent( ctx ) {
            ctx.dispatch('apiGetLoginPresent', {
                success: async () => {
                    await ctx.dispatch('fetchAvailableLoginPresent');
                },
            });
            ctx.dispatch('fetchProfileData');
        },
    },

    mutations: {
        updateProfileData( state, data ) {
            state.profileData = data;
        },

        setProfileStatus( state, data ) {
            state.profileData.status = data;
        },

        updateAvailableLoginPresent( state, data ) {
            state.availableLoginPresent = data.availablePresent;
        },
    },

    state: {
        profileData: {},
        availableLoginPresent: null,
    },

    getters: {
        getProfileData( state ) {
            return state.profileData;
        },

        getAvatarUrl( state ) {
            if (state.profileData.id !== undefined) {
                return process.env.VUE_APP_API_BASE_URL +
                    'static/player/avatar/' +
                    state.profileData.id + '.png?t=' + Date.now();
            }

            return null;
        },
        getAvailableLoginPresent( state ) {
            return state.availableLoginPresent;
        },
    },
};
