import ApiCommonStore from '@/store/common/api/ApiCommonStore';
import ApiMethodStore from '@/store/common/api/ApiMethodStore';
import ApiLevelStore from '@/store/common/api/ApiLevelStore';
import ApiNotificationStore from '@/store/common/api/ApiNotificationStore';
import ApiMapStore from '@/store/common/api/ApiMapStore';

export default {
    modules: {
        ApiCommonStore,
        ApiMethodStore,
        ApiLevelStore,
        ApiNotificationStore,
        ApiMapStore,
    },
};
