<template>
  <div class="login-present-modal__overlay" v-if="getAvailableLoginPresent !== null">
    <div class="login-present-modal__window">
      <div class="login-present-modal__container">
        <div class="login-present-modal__day"
             :class="[
                'login-present-modal__day-' + getAvailableLoginPresent,
              ]">
        </div>
        <button class="login-present-modal__button" @click="getLoginPresent()" />
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'LoginPresentModal',
  data() {
    return {
      presentCardCount: 7,
    };
  },
  methods: {
    ...mapActions(['fetchAvailableLoginPresent', 'getLoginPresent']),
  },
  computed: {
    ...mapGetters(['getAvailableLoginPresent']),
  },
};
</script>

<style scoped lang="scss">
.login-present-modal {
  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.57);
    top: 0;
    left: 0;
    z-index: 1000;
  }

  &__window {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  &__container {
    position: relative;
    width: 99%;
    max-width: 900px;
    max-height: 670px;
    height: 100%;
    background-image: url('/public/static/img/modal/bg.png');
    background-size: contain;
  }
  &__day {
    display: flex;
    justify-content: center;
    width: 88%;
    height: 100%;
    height: 100%;
    margin: 37% auto 0 auto;
    background-size: contain;
    &-1 {
      background-image: url('/public/static/img/modal/days-group-1.png');
    }
    &-2 {
      background-image: url('/public/static/img/modal/days-group-2.png');
    }
    &-3 {
      background-image: url('/public/static/img/modal/days-group-3.png');
    }
    &-4 {
      background-image: url('/public/static/img/modal/days-group-4.png');
    }
    &-5 {
      background-image: url('/public/static/img/modal/days-group-5.png');
    }
    &-6 {
      background-image: url('/public/static/img/modal/days-group-6.png');
    }
  }
  &__button {
    position: absolute;
    bottom: -4%;
    right: calc(50% - 16.5%);
    width: 33%;
    height: 12%;
    background-image: url('/public/static/img/modal/button-get.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
}
</style>
