<template>
  <div class="resource-bar">
    <div class="resource-bar__count crystals">
      {{ getProfileData.crystals }}
    </div>
    <div class="resource-bar__count coins">
      {{ getProfileData.coins }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ResourcesBar',
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getProfileData']),
  },

};
</script>

<style scoped lang="scss">

.resource-bar {
  position: absolute;
  background-image: url('/public/static/img/bar/bar-left-bg.png');
  background-size: contain;
  height: 30%;
  width: 7%;
  bottom: 0.8%;
  &__count {
    position: absolute;
    left: 0;
    display: flex;
    justify-content: flex-end;
    font-family: 'leksa', sans-serif;
    font-size: 1vw;
    line-height: 1.2vw;
    color: #0b504a;
    width: 55%;
    &.crystals {
      top: 35%;
    }
    &.coins {
      top: 76.5%;
    }
  }
}

</style>
