export default {
    actions: {
        loadLevel( ctx, id ) {
            ctx.dispatch('apiGetLevel', {
                id,
                success: ( data ) => {
                    ctx.commit('loadLevelData', data);
                },
            });
        },
    },

    mutations: {
        loadLevelData( state, data ) {
            state.levels.push(data);
        },
    },

    state: {
        levels: [],
    },

    getters: {
        getLevel: ( state ) => ( id ) => {
            let level = state.levels.find(level => level.id === id);
            if (level === undefined) {
                return null;
            }
            return level;
        },

    },
};
