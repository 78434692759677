<template>
  <WorldPopupNotification v-if="getNewNotification" :notification="getNewNotification"/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WorldPopupNotification from '@/components/modals/WorldPopupNotification.vue';

export default {
  name: 'PopupNotification',
  components: { WorldPopupNotification },
  data() {
    return {
      notifications: [],
    };
  },
  methods: {
    ...mapActions(['loadNewNotifications']),
  },
  computed: {
    ...mapGetters(['getNewNotification']),
  },
  mounted() {
    this.loadNewNotifications();
  },
};
</script>
