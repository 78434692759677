const q = ( ctx, params ) => { ctx.dispatch('apiQuery', params); };

// collection of API methods with property mapping
export default {
    actions: {
        async apiGetAllNotifications( ctx, payload ) {
            q(ctx, {
                url: 'notification/all',
                preloader: false,
                method: 'get',
                success: payload.success,
            });
        },
        apiGetPaginatedNotifications( ctx, payload ) {
            q(ctx, {
                url: 'notification/',
                preloader: false,
                method: 'get',
                params: payload.params,
                success: payload.success,
            });
        },
        async apiGetNewNotifications( ctx, payload ) {
            q(ctx, {
                url: 'notification/new',
                preloader: false,
                method: 'get',
                success: payload.success,
            });
        },
        async apiRegisterNotificationShow( ctx, payload ) {
            q(ctx, {
                url: 'notification/register-show',
                preloader: false,
                method: 'post',
                params: { id: payload.id },
                success: payload.success,
            });
        },
    },
};
