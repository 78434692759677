export default {
    actions: {
        loadNotifications( ctx ) {
            ctx.dispatch('apiGetAllNotifications', {
                success: ( data ) => {
                    ctx.commit('loadNotificationsData', data);
                },
            });
        },
        loadNewNotifications( ctx ) {
            ctx.dispatch('apiGetNewNotifications', {
                success: ( data ) => {
                    ctx.commit('loadNotificationsData', data);
                },
            });
        },
        registerNotificationShow( ctx, payload ) {
            ctx.commit('setNotificationIsShowed', payload.id);
            ctx.dispatch('apiRegisterNotificationShow', {
                id: payload.id,
                success: () => {
                    ctx.dispatch('loadNewNotifications');
                },
            });
        },
    },

    mutations: {
        loadNotificationsData( state, data ) {
            state.notifications = data;
        },
        setNotificationIsShowed( state, id ) {
            let index = state.notifications.findIndex(
                notification => notification.id === id);
            state.notifications[index].new = false;
        },
    },

    state: {
        notifications: [],
    },

    getters: {
        getNotifications: ( state ) => {
            return state.notifications;
        },
        getNotification: ( state ) => ( id ) => {
            let notification = state.notifications.find(
                notification => notification.id === id);
            if (notification === undefined) {
                return null;
            }
            return notification;
        },
        getNewNotifications: ( state ) => {
            return state.notifications.reduce(
                notification => notification.new === true);
        },
        getNewNotification: ( state ) => {
            return state.notifications.find(
                notification => notification.new === true) ?? null;
        },
    },
};
