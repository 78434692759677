const q = ( ctx, params ) => { ctx.dispatch('apiQuery', params); };

// collection of API methods with property mapping
export default {
    actions: {
        async apiMapHomework( ctx, payload ) {
            q(ctx, {
                url: 'map/homework',
                preloader: false,
                method: 'get',
                success: payload.success,
            });
        },
    },
};
