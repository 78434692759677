<template>
  <div class="world-popup-notification__overlay">
    <div class="world-popup-notification__window">
      <div class="world-popup-notification__container">
        <div class="world-popup-notification__text">{{ notification.text }}</div>
        <div class="world-popup-notification__target" v-if="notification.target_image">
          <img :src="getApiFullUrl(notification.target_image)" alt="target image">
        </div>
        <div class="world-popup-notification__character" v-if="notification.character_image">
          <img :src="getApiFullUrl(notification.character_image)" alt="character image">
        </div>
        <button class="world-popup-notification__button" @click="close()">
          {{ notification.action_text }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import { urlHelper } from '@/components/mixins/urlHelper';

export default {
  name: 'WorldPopupNotification',
  mixins: [urlHelper],
  props: {
    notification: {
      id: null,
      name: null,
      text: null,
      new: null,
      show_in_list: null,
      target_image: null,
      character_image: null,
      action_href: null,
      action_text: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(['registerNotificationShow']),
    close() {
      this.registerNotificationShow({ id: this.notification.id });
      if (this.notification.action_href !== '') {
        if (this.notification.action_href.startsWith('http')) {
          window.open(this.notification.action_href, '_blank').focus();
        } else {
          window.open(this.notification.action_href);
        }

      }
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.world-popup-notification {
  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.61);
    top: 0;
    left: 0;
    z-index: 1000;
  }

  &__window {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__container {
    position: relative;
    width: 99%;
    max-width: 500px;
    max-height: 500px;
    min-height: 250px;
    height: auto;
    border: 4px solid #226b8c;
    outline: 2px solid black;
    border-radius: 20px;
    padding: 20px 20px 120px;
    background-image: url('/public/static/img/modal/bg-circle-yellow.png');
    background-repeat: repeat;
  }

  &__text {
    text-align: center;
    font-family: 'leksa', sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #0b504a;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 30px 10px;
    bottom: -15%;
    right: calc(50% - 16.5%);
    width: 33%;
    height: 12%;
    background-color: #7ebb3b;
    border: 2px solid #000100;
    border-radius: 60px;
    color: white;
    font-size: 20px;
    font-weight: 800;
    text-transform: uppercase;
  }

  &__target {
    position: absolute;
    left: -6%;
    bottom: -6%;

    img {
      max-height: 140px;
      max-width: 140px;
    }
  }

  &__character {
    position: absolute;
    right: -2%;
    bottom: -4%;

    img {
      max-height: 140px;
      max-width: 140px;
    }
  }
}
</style>
