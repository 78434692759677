import { createStore } from 'vuex';

import WheelPageStore from '@/store/modules/pages/WheelPageStore';
import LibraryStore from '@/store/modules/assets/LibraryStore';
import ProfileStore from '@/store/modules/global/ProfileStore';
import AvatarPage from '@/store/modules/pages/AvatarPage';
import ApiStore from '@/store/common/api/ApiStore';
import GalleryStore from '@/store/modules/pages/GalleryStore';
import LevelStore from '@/store/modules/LevelStore';
import NotificationStore from '@/store/modules/NotificationStore';

export default createStore({
    // strict: true,
    modules: {
        WheelPageStore,
        LibraryStore,
        ProfileStore,
        GalleryStore,
        AvatarPage,
        ApiStore,
        LevelStore,
        NotificationStore,
    },
});
