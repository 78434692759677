<template>
  <div class="map page__wrap__container__view">
    <LoginPresentModal v-if="getAvailableLoginPresent!==null"/>
    <PopupNotification/>
    <router-link class="map__link" to="/avatar">
      <AvatarPreview :update="false" class="map-avatar"/>
    </router-link>
    <MapLink :external="true" name="homework" :action="homeworkHandler"></MapLink>
    <MapLink :external="true" image="battle-ring" name="battle"></MapLink>
    <MapLink :external="true" name="tower"></MapLink>
    <MapLink :external="true" name="calc"></MapLink>
    <MapLink :external="true" name="soroban"></MapLink>
    <MapLink :external="true" name="olympic"></MapLink>

    <MapLink :external="false" name="gallery"></MapLink>

    <MapLink :external="false" name="wheel" :active="false"></MapLink>
    <MapLink :external="false" name="movies" :active="false"></MapLink>
    <MapLink :external="false" name="house-1" :active="false"></MapLink>
    <MapLink :external="false" name="house-2" :active="false"></MapLink>
    <MapLink :external="false" name="house-3" :action="()=>{this.$router.push({name:'NotificationsPage'})}"></MapLink>
    <MapLink :external="false" name="reactor" :clickable="false"></MapLink>
    <MapLink :external="false" name="store" :active="false"></MapLink>
    <MapLink :external="false" name="skyscrapper" :active="false"></MapLink>
    <MapLink :external="false" name="home" :active="false"></MapLink>
    <resources-bar/>
    <level-bar/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import BaseExternalLink from '@/components/base/BaseExternalLink';
import MapImage from '@/modules/map/MapImage';
import BaseImage from '@/components/base/BaseImage';
import AvatarPreview from '@/modules/avatar/AvatarPreview';
import MapLink from '@/modules/map/MapLink';
import LoginPresentModal from '@/components/modals/LoginPresentModal.vue';
import ResourcesBar from '@/components/ResourcesBar.vue';
import LevelBar from '@/components/LevelBar.vue';
import PopupNotification from '@/components/PopupNotification.vue';

export default {
  name: 'MapPage',
  components: {
    PopupNotification,
    LevelBar,
    ResourcesBar,
    LoginPresentModal,
    MapLink,
    BaseExternalLink,
    MapImage,
    BaseImage,
    AvatarPreview,
  },
  methods: {
    ...mapActions(['fetchAvailableLoginPresent', 'loadNotifications', 'apiMapHomework', 'loadNewNotifications']),
    ...mapMutations(['decrementPreloaderState', 'incrementPreloaderState']),
    redirectToExternalLink( name ) {
      const url = process.env.VUE_APP_PLATFORM_BASE_URL + process.env.VUE_APP_GAME_PATH + '#' + name;
      window.open(url, '_blank').focus();
    },
    homeworkHandler() {
      this.apiMapHomework({
        success: ( data ) => {
          if (data.notification) {
            this.loadNotifications();
          } else {
            this.redirectToExternalLink();
          }
        },
      });
    },
  },

  computed: {
    ...mapGetters(['getProfileData', 'getAvatarUrl', 'getAvailableLoginPresent']),
  },

  async mounted() {
    await this.fetchAvailableLoginPresent();
  },

};

</script>
