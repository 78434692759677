<template>
  <div
    class="level-bar"
    :class="[
                'level-bar-' + levelBar,
              ]"
  >
    <img v-if="getCurrAchievement" class="level-bar__achievement"
         alt="latest achievement"
         :src="getApiFullUrl(getCurrAchievement?.img_unlocked.substr(1))">
    <div class="level-bar__current">
      уровень
      <span>{{ currentLevel }}</span>
    </div>
    <div class="level-bar__step">
      <span>{{ this.getProfileData.level_step }}/{{ this.getLevel(this.getProfileData.level)?.steps.length }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { urlHelper } from '@/components/mixins/urlHelper';

export default {
  mixins: [urlHelper],
  name: 'LevelBar',
  beforeMount() {
    this.fetchAllAchievements();
  },
  methods: {
    ...mapActions(['loadLevel', 'fetchAllAchievements']),
  },
  computed: {
    ...mapGetters(['getProfileData', 'getLevel', 'getCurrAchievement']),
    currentLevel() {
      if (this.getProfileData.level !== undefined) {
        this.loadLevel(this.getProfileData.level);
        return this.getProfileData.level;
      }
      return null;
    },
    levelBar() {
      if (!this.getProfileData || !this.getLevel(this.getProfileData.level)) {
        return 0;
      }
      let levelBarValue = this.getProfileData.level_step
        / this.getLevel(this.getProfileData.level).steps.length
        / 0.2;
      levelBarValue = Math.ceil(levelBarValue);
      return levelBarValue > 5 ? 5 : levelBarValue;
    },
  },

};
</script>

<style scoped lang="scss">
.level-bar {
  position: absolute;
  right: 0;
  background-position: bottom right;
  background-size: contain;
  height: 35%;
  width: 23%;
  bottom: 2%;
  z-index: 9;

  &-0 {
    background-image: url('/public/static/img/bar/bar-right-0.png');
  }

  &-1 {
    background-image: url('/public/static/img/bar/bar-right-1.png');
  }

  &-2 {
    background-image: url('/public/static/img/bar/bar-right-2.png');
  }

  &-3 {
    background-image: url('/public/static/img/bar/bar-right-3.png');
  }

  &-4 {
    background-image: url('/public/static/img/bar/bar-right-4.png');
  }

  &-5 {
    background-image: url('/public/static/img/bar/bar-right-5.png');
  }

  &__achievement {
    position: absolute;
    right: 0;
    top: 20%;
    width: 30%;
    height: 30%;
  }

  &__current {
    position: absolute;
    right: 6.5%;
    top: 66.5%;
    display: flex;
    justify-content: flex-end;
    font-family: 'leksa', sans-serif;
    font-size: 0.5vw;
    line-height: 1.2vw;
    color: #0b504a;
    font-weight: 900;
    font-style: normal;

    span {
      font-size: 1vw;
      font-weight: 100;
      font-style: italic;
    }
  }

  &__step {
    position: absolute;
    right: 6%;
    top: 91%;
    display: flex;
    justify-content: flex-end;
    font-family: 'leksa', sans-serif;
    font-size: 0.5vw;
    line-height: 1.2vw;
    color: #0b504a;
    font-weight: 900;
    font-style: normal;

    span {
      font-size: 0.8vw;
      font-weight: 100;
      font-style: italic;
    }
  }
}

</style>
