const q = ( ctx, params ) => { ctx.dispatch('apiQuery', params); };

// collection of API methods with property mapping
export default {
    actions: {
        apiGetLevels( ctx ) {
            q(ctx, {
                url: 'level',
                preloader: false,
                method: 'get',
            });
        },
        async apiGetLevel( ctx, payload ) {
            q(ctx,
                {
                    url: 'level/view',
                    method: 'get',
                    params: { id: payload.id },
                    success: payload.success,
                },
            );
        },
    },
};
