const q = ( ctx, params ) => { ctx.dispatch('apiQuery', params) }

// collection of API methods with property mapping
export default {
    actions: {
        // profile methods
        apiProfileInfo( ctx, cb ) {
            q(ctx, {
                url: 'profile',
                preloader: false,
                method: 'get',
                success: ( r ) => {
                    cb.success({
                        id: r.id,
                        name: r.name,
                        level: r.level,
                        level_step: r.level_step,
                        coins: r.coins,
                        crystals: r.crystals,
                        homeworkDays: r.homework_days,
                    })
                },
            })
        },
        apiAvailableLoginPresent( ctx, cb ) {
            q(ctx, {
                url: 'profile/login-present',
                preloader: false,
                method: 'get',
                success: cb.success,
            })
        },

        apiGetLoginPresent( ctx, cb ) {
            q(ctx, {
                url: 'profile/login-present',
                preloader: false,
                method: 'post',
                success: cb.success,
            })
        },

        // avatar methods
        apiGetAvatar( ctx, cb ) {
            q(ctx, { url: 'avatar', method: 'get', success: cb.success })
        },
        apiGetAvatarParts( ctx, cb ) {
            q(ctx, { url: 'avatar/get-parts', success: cb.success })
        },
        apiSetAvatar( ctx, config ) {
            q(ctx,
                { url: 'avatar', data: config.data, success: config.success })
        },

        // register methods
        apiSetGender( ctx, isMale ) {
            q(ctx, {
                url: 'register/set-gender',
                data: { isMale: isMale },
                preloader: false,
            })
        },
        apiSetName( ctx, name ) {
            q(ctx, {
                url: 'register/set-name',
                data: { name: name },
                preloader: false,
            })
        },

        // wheel methods
        apiWheelResult( ctx, cb ) {
            q(ctx, {
                url: 'wheel-of-fortune/get-result',
                success: cb.success,
                preloader: false,
            })
        },
        apiWheelStatus( ctx, cb ) {
            q(ctx, {
                url: 'wheel-of-fortune/get-status',
                success: cb.success,
                preloader: false,
            })
        },
        apiWheelItems( ctx, cb ) {
            q(ctx, { url: 'wheel-of-fortune/get-items', success: cb.success })
        },

        // reward methods
        apiGetAllAchievements( ctx, cb ) {
            q(ctx, { url: 'achievement/all', success: cb.success })
        },
        apiGetNewAchievements( ctx, cb ) {
            q(ctx, { url: 'achievement/new', success: cb.success })
        },
        apiGetAchievementMap( ctx, cb ) {
            q(ctx, { url: 'achievement/map', success: cb.success })
        },

    },
}
