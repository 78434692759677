import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'MapPage',
        component: () => import('../pages/MapPage.vue'),
    },
    {
        path: '/wheel',
        name: 'WheelPage',
        component: () => import('../pages/WheelPage.vue'),
    },
    {
        path: '/house',
        name: 'HousePage',
        component: () => import('../pages/HousePage.vue'),
    },
    {
        path: '/avatar',
        name: 'AvatarPage',
        component: () => import('../pages/AvatarPage.vue'),
    },
    {
        path: '/gallery',
        name: 'GalleryPage',
        component: () => import('../pages/GalleryPage.vue'),
    },
    {
        path: '/notifications',
        name: 'NotificationsPage',
        component: () => import('../pages/NotificationsPage.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
